var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#555555"}},[_c('v-layout',{style:(_vm.$vuetify.breakpoint.name == 'xs'
        ? {
            'font-size': '13px',
          }
        : _vm.$vuetify.breakpoint.name == 'sm'
        ? {
            'font-size': '13px',
          }
        : _vm.$vuetify.breakpoint.name == 'md'
        ? {
            'font-size': '16px',
          }
        : {
            'font-size': '16px',
          }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"ranade-m",staticStyle:{"color":"white"},attrs:{"xs12":"","text-center":"","pt-6":"","pb-2":""}},[_vm._v(" Cyber Craft Innovations ")]),_c('v-flex',{staticClass:"ranade-m",staticStyle:{"color":"white"},attrs:{"xs12":"","text-center":""}},[_vm._v(" © 2025 All rights reserved ")]),_c('v-flex',{attrs:{"xs5":"","text-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","j":""}},[_c('v-flex',{attrs:{"xs3":"","sm2":"","md1":"","pa-3":"","align-self-center":""}},[_c('v-icon',{staticClass:"element",attrs:{"color":"white"}},[_vm._v("mdi-linkedin")])],1),_c('v-flex',{attrs:{"xs3":"","sm2":"","md1":"","pa-3":"","align-self-center":""}},[_c('v-icon',{staticClass:"element",attrs:{"color":"white"}},[_vm._v(" mdi-facebook-messenger ")])],1),_c('v-flex',{attrs:{"xs3":"","sm2":"","md1":"","pa-3":"","align-self-center":""}},[_c('v-icon',{staticClass:"element",attrs:{"color":"white"}},[_vm._v(" mdi-twitter ")])],1)],1)],1),_c('v-flex',{attrs:{"xs8":"","pb-2":""}},[_c('v-divider',{staticClass:"border-opacity-75",attrs:{"color":"#9ccbed"}})],1),_c('v-flex',{staticClass:"ranade-l",staticStyle:{"color":"white"},attrs:{"xs12":"","py-4":"","text-center":""}},[_vm._v(" Designed and Developed by "),_c('a',{staticStyle:{"text-decoration":"none","color":"#ffd06c"},attrs:{"href":"https://cybercraftinnovations.com/"}},[_vm._v("Cyber Craft Innovations")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }