<template>
  <div class="app">
    <!-- <v-app-bar color="#5d54a4"> -->
    <v-layout wrap class="hidden-sm-and-down app px-0">
      <v-flex xs12>
        <v-app-bar color="white" class="px-0" flat>
          <!-- <header> -->
          <v-layout wrap justify-center class="hidden-sm-and-down app px-0">
            <v-flex xs12>
              <!-- <v-img src="../../assets/Images/hd.jpg"> -->
              <v-layout wrap fill-height>
                <v-flex xs12>
                  <!-- <v-card color="white" elevation="0"> -->
                  <v-layout wrap>
                    <v-flex xs12 md7>
                      <router-link to="/" style="text-decoration: none">
                        <v-layout wrap>
                          <v-flex py-4 xs3>
                            <v-img contain style="cursor: pointer" text src="../assets/Images/logo.png"
                              height="50px"></v-img>
                          </v-flex>
                        </v-layout>
                      </router-link>
                    </v-flex>

                    <v-layout wrap justify-space-between class="headerfont">
                      <!-- <v-flex xs1 pt-1><a class="interSB" style="text-decoration:none; color:white" href="/#about">About</a></v-flex> -->
                      <!-- <v-hover v-slot="{ hover }"> -->

                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                        <router-link to="/" style="text-decoration: none">
                          <v-hover v-slot="{ hover }"><span :style="hover ? 'color:#5ce1e6' : 'color:black'">
                              Home</span></v-hover>

                        </router-link>
                        <v-layout pt-2 wrap justify-center fill-height v-if="$route.path == '/'">
                          <v-flex xs6 align-self-center>
                            <v-progress-linear value="100" color="#5ce1e6"></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- v-scroll-to="{
                              el: '#my-home',
                              duration: 900,
                              easing: 'ease-in',
                            }" -->
                      <!-- </router-link> -->
                      <!-- </v-hover> -->

                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center v-scroll-to="{
                        el: '#our-aboutus',
                        duration: 500,
                        easing: 'ease-in',
                      }"> <router-link to="/" style="text-decoration: none"><v-hover v-slot="{ hover }"> <span
                              :style="hover ? 'color:#5ce1e6' : 'color:black'">About
                              Us</span></v-hover></router-link>
                      </v-flex>


                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center> <router-link
                          to="/coursesPage" style="text-decoration: none;"><v-hover v-slot="{ hover }"><span
                              :style="hover ? 'color:#5ce1e6' : 'color:black'">Specialized</span></v-hover></router-link>
                        <v-layout pt-2 wrap justify-center fill-height
                          v-if="$route.path == '/coursesPage' || $route.path == '/courseDetails'">
                          <v-flex xs6 align-self-center>
                            <v-progress-linear value="100" color="#5ce1e6"></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-hover v-slot="{ hover }">
                            <v-flex
                              style="text-align: justify; cursor: pointer"
                              text-center
                              :style="hover ? 'color:#5ce1e6' : 'color:black'"
                              align-self-center
                              v-scroll-to="{
                                el: '#my-events',
                                duration: 900,
                                easing: 'ease-in',
                              }"
                              >Events
                            </v-flex>
                          </v-hover> -->
                      <!-- v-scroll-to="{
                        el: '#my-services',
                        duration: 900,
                        easing: 'ease-in',
                      }" -->

                      <v-flex style="text-align: justify; cursor: pointer" text-center align-self-center>
                        <router-link to="/contactUs" style="text-decoration: none;"> <v-hover v-slot="{ hover }"> <span
                              :style="hover ? 'color:#5ce1e6' : 'color:black'">Contact Us </span></v-hover></router-link>
                        <v-layout pt-2 wrap justify-center fill-height v-if="$route.path == '/contactUs'">
                          <v-flex xs6 align-self-center>
                            <v-progress-linear value="100" color="#5ce1e6"></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- v-scroll-to="{
                              el: '#my-contacts',
                              duration: 900,
                              easing: 'ease-in',
                            }" -->
                    </v-layout>
                  </v-layout>
                  <!-- </v-card> -->
                </v-flex>
              </v-layout>
              <!-- </v-img> -->
            </v-flex>
          </v-layout>
          <!-- </header> -->
        </v-app-bar>
      </v-flex>
    </v-layout>

    <!-- MOBILE SCREEN -->


    <v-layout wrap class="hidden-md-and-up">
      <v-flex xs12>
        <v-app-bar color="white" class="px-0">
          <v-layout class="hidden-md-and-up">
            <v-flex xs12>
              <!-- <v-img src="../../assets/Images/hd.jpg"> -->
              <v-layout wrap>
                <v-flex xs11 align-self-start>
                  <router-link to="/" style="text-decoration: none">
                    <v-layout wrap justify-start>
                      <v-flex xs5 pa-5>
                        <v-img contain text src="../assets/Images/logo.png" height="40px"></v-img>
                      </v-flex>
                    </v-layout>
                  </router-link>
                </v-flex>
                <v-flex xs1 pt-5>
                  <v-app-bar-nav-icon @click="drawer = !drawer">
                    <v-icon color="White">mdi-menu</v-icon>
                  </v-app-bar-nav-icon>
                </v-flex>
              </v-layout>
              <!-- </v-img> -->
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
      <v-navigation-drawer right v-model="drawer" app fixed temporary>
        <v-layout wrap justify-center>
          <v-flex>
            <v-list dense nav>
              <v-list-item-group active-class="blue--text text--accent-4">
                <v-list-item>
                  <v-list-item-icon>
                    <v-img height="20px" contain></v-img>
                  </v-list-item-icon>

                  <v-list-item-title><router-link to="/" style="text-decoration: none;"><span class="headerfont"
                        style="color:black" @click="drawer = false">Home</span></router-link></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-img height="20px" contain></v-img>
                  </v-list-item-icon>
                  <v-list-item-title><router-link to="/" style="text-decoration: none;"><span class="headerfont"
                        style="color:black" @click="drawer = false" v-scroll-to="{
                          el: '#our-aboutus',
                          duration: 500,
                          easing: 'ease-in',
                        }">About Us</span></router-link></v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-img height="20px" contain></v-img>
                  </v-list-item-icon>
                  <v-list-item-title><router-link to="/coursesPage" style="text-decoration: none;"><span
                        class="headerfont" style="color:black"
                        @click="drawer = false">Courses</span></router-link></v-list-item-title>
                </v-list-item>

                <!-- <v-list-item>
                  <v-list-item-icon>
                    <v-img height="20px" contain></v-img>
                  </v-list-item-icon>
                  <v-list-item-title
                    ><span
                      class="headerfont"
                      @click="drawer = false"
                      v-scroll-to="{
                        el: '#my-events',
                        duration: 900,
                        easing: 'ease-in',
                      }"
                      >Events</span
                    ></v-list-item-title
                  >
                </v-list-item> -->

                <v-list-item>
                  <v-list-item-icon>
                    <v-img height="20px" contain></v-img>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <router-link to="/contactUs" style="text-decoration: none;">
                      <span style="color:black" class="headerfont" @click="drawer = false">Contact Us</span>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-flex>
        </v-layout>
      </v-navigation-drawer>
    </v-layout>
    <!-- </v-app-bar> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
};
</script>
<style>
.router-link {
  text-decoration: none;
}

.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.fixItem {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  /* overflow: hidden;
      position: relative;
      height: 100%; */
}

.fnt1 {
  font-size: 13px;
  font-weight: bold;
}

.app-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  /* adjust to desired height */
  background-color: #fff;
  /* adjust to desired background color */
  border-bottom: 1px solid #ccc;
  /* adjust to desired border style */
  z-index: 999;
  /* adjust to desired z-index */
}

.app-content {
  margin-top: 64px;
  /* adjust to match app bar height */
}

.gradientorange {
  /* background-image:inear-gradient(255.36deg, #7BC142 48.09%, #E95D49 127.63%) ; */
  background-image: linear-gradient(255.36deg, #7bc142 48.09%, #e95d49 127.63%);
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  /* adjust height as needed */
  background-color: #fff;
  /* adjust background color as needed */
  border-bottom: 1px solid #ccc;
  /* add a border if needed */
  z-index: 999;
  /* adjust z-index as needed */
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
</style>
