import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import VueHorizontal from 'vue-horizontal';
import AOS from 'aos'
import 'aos/dist/aos.css'
import VScrollTo from 'v-scroll-to'

Vue.use(VScrollTo)


Vue.use(VueHorizontal);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')