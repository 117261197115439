<template>
  <div>
    <v-app>
      
      <!-- <v-main> -->
        <v-layout wrap>
          <v-flex xs12>
            <v-main>
            <Header/>
            <router-view> 
          
             </router-view>
             <Footer/>
            </v-main>
          </v-flex>
        </v-layout>
  
        <!-- <HelloWorld/> -->
      <!-- </v-main> -->
    </v-app>
    </div>
  </template>
  
  <script>
  import "./assets/style/fonts.css"
  import "./assets/style/style.css"
  import Header from "./components/HeaderPage.vue"
  import Footer from "./components/FooterPage.vue"
  
  
  // import HelloWorld from './components/HelloWorld';
  
  export default {
    name: 'App',
  
    components: {
      // HelloWorld,
      Header,
      Footer,
    },
  
    data: () => ({
      //
    }),
  };
  </script>
  